.Reasons {
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 2rem; /* Gap between columns */
}

.left-r {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
    gap: 0.5rem; /* Gap between grid items */
    grid-auto-rows: 1fr; /* Automatically adjusts row height */
}

.left-r > img {
    object-fit: cover;
    width: 100%; /* Make sure images fill their containers */
    height: 100%; /* Maintain aspect ratio and fill container */
}

.left-r > :nth-child(1) {
    width: fit-content;
    grid-row: span 2; /* Spans two rows */
    width: 100%; 
    display: flex;
    height: 28.3rem; /* Fixed height */
    justify-content: center;
    align-items: center;
    object-fit: fill;
}

.left-r > :nth-child(2) {
    max-width: 100%;
    grid-column: span 2; /* Spans two columns */
    height: 16rem; /* Fixed height */
}

.left-r > :nth-child(3) {
    max-width: 100%;
    width: fit-content; /*Adjust to fit container*/
    height: 11.8rem; /* Adjust height based on content */
}

.left-r > :nth-child(4) {
    object-fit: fill;
    height: 11.8rem; /* Fixed height */
}

.right-r {
    /* Add specific styles if needed, e.g., padding, background */
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-r>span{
    font-weight: bold;
    color: var(--orange);
}
.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

.details-r>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners>span{
    margin-top: 2rem;
}
.partners>img{
    width: 3rem;
}
/* Media queries for smaller screens */
@media (max-width: 768px) {
    .Reasons {
        grid-template-columns: 1fr; /* Single column layout */
        gap: 1rem; /* Reduce gap for smaller screens */
        padding: 0 1rem; /* Adjust padding for smaller screens */
    }

    .left-r {
        grid-template-columns: 1fr 1fr; /* Two columns instead of three */
        gap: 0.5rem;
    }

    .left-r > :nth-child(1) {
        grid-row: span 2; /* Retain spanning two rows */
        height: 14rem; /* Make the first image shorter */
        width: 100%; /* Full width */
        display: none;
    }

    .left-r > :nth-child(2) {
        grid-column: span 2; /* Spans two columns */
        height: auto; /* Let the height adjust based on content */
        width: 100%; /* Full width */
    }

    .left-r > :nth-child(3) {
        width: 100%; /* Full width */
        height: 9.5rem; /* Let the height adjust based on content */
    }

    .left-r > :nth-child(4) {
        width: 100%; /* Full width */
        height: auto; /* Let the height adjust based on content */
    }

    .right-r > div {
        font-size: 2rem; /* Adjust font size for smaller screens */
    }

    .details-r > div {
        font-size: 0.9rem; /* Adjust font size for smaller screens */
    }

    .partners > img {
        width: 2rem; /* Adjust image size for smaller screens */
    }
}

